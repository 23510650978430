<template>
    <div class="login-main">         
        <div class="container">            
            <div class="vx-row justify-center login-inner ">
                <div class="login-colors-coustmize">
                    <vs-button @click="active = !active" :aria-pressed="active ? 'true' : 'false'"  radius type="filled" color="primary" class="login-theme-btn" icon-pack="feather" icon="icon-edit-2"></vs-button>
                    <ul :class="{ activeToggle: active }">
                        <!-- Color Square -->
                        <li class="bg-first"></li>
                        <li class="bg-second"></li>
                        <li class="bg-third"></li>                        
                        <li class="bg-danger"></li>
                        <li class="bg-fourth"></li>
                    </ul>
                </div>
                <div class="vx-col md:w-1/2 w-full">
                    <div class="login-form">
                        <h2>Login</h2>
                        <form data-vv-scope="loginForm">
                            <div class="form-grp">
                                <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m256 0c-141.159 0-256 114.841-256 256s114.841 256 256 256 256-114.841 256-256-114.841-256-256-256zm0 482c-124.617 0-226-101.383-226-226s101.383-226 226-226 226 101.383 226 226-101.383 226-226 226z"/><path d="m256 270c57.897 0 105-47.103 105-105s-47.103-105-105-105-105 47.103-105 105 47.103 105 105 105zm0-180c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75z"/><path d="m424.649 335.443c-19.933-22.525-48.6-35.443-78.649-35.443h-180c-30.049 0-58.716 12.918-78.649 35.443l-7.11 8.035 5.306 9.325c34.817 61.187 100.131 99.197 170.453 99.197s135.636-38.01 170.454-99.198l5.306-9.325zm-168.649 86.557c-55.736 0-107.761-28.197-138.383-74.295 13.452-11.352 30.579-17.705 48.383-17.705h180c17.804 0 34.931 6.353 48.383 17.705-30.622 46.098-82.647 74.295-138.383 74.295z"/></g></svg>                                    
                                <vs-input placeholder="Email" name="email" v-model="email" class="w-full" />                                           
                            </div>
                            <div class="form-grp">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z" fill="#000000" data-original="#000000" style="" class=""/>
                                            <path d="M336,160h-16v-32c0.974-16.673-11.753-30.978-28.426-31.952c-0.444-0.026-0.889-0.042-1.334-0.048h-68.48     c-16.786,0.33-30.127,14.205-29.797,30.992c0.007,0.336,0.019,0.672,0.037,1.008v32h-16c-26.51,0-48,21.49-48,48v128     c0,26.51,21.49,48,48,48h160c26.51,0,48-21.49,48-48V208C384,181.49,362.51,160,336,160z M224,128h64v32h-64V128z M352,336     c0,8.837-7.163,16-16,16H176c-8.837,0-16-7.163-16-16V208c0-8.837,7.163-16,16-16h160c8.837,0,16,7.163,16,16V336z" fill="#000000" data-original="#000000" style="" class=""/>
                                            <path d="M256,224c-17.672,0.18-31.852,14.653-31.672,32.325c0.114,11.18,6.055,21.49,15.672,27.195V320c17.673,0,32-14.327,32-32     v-4.48c15.2-9.017,20.212-28.648,11.195-43.848C277.49,230.055,267.18,224.114,256,224z" fill="#000000" data-original="#000000" style="" class=""/>
                                        </g>
                                    </g>
                                </g>
                                </g></svg>                                    
                                <vs-input type="password" placeholder="Password" v-model="password" class="w-full" />                                            
                            </div>
                            <div class="flex flex-wrap items-center justify-between w-full">                                    
                                <router-link to="Forgotpassword" class="forgot-pass">Forgot Password? </router-link>
                                <vs-button color="primary" id="user-login" :disabled="button_loading" type="filled" 
                                class="float-right">Login</vs-button>
                            </div>
                        </form>
                    </div>
                </div> 
                <div class="vx-col md:w-1/2 w-full p-0">
                    <div class="login-right">
                        <span class="login-bg"></span>
                        <div class="login-img"><img src="../../assets/images/login-lock-right.svg"></div>
                    </div>
                </div>                                            
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: "",
            password: "",  
            active: false
        };
    },
    methods: {
       
    }
    
}
</script>

<style lang="scss">
.login-main {
    align-content: flex-end;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    padding: 24px 0px;
    display: flex;     
    align-items: center;   
    position: relative;     
    &:after{
        position: fixed;
        left: -130px;
        bottom: -130px;    
        content: "";
        width: 350px;
        height: 350px;
        z-index: -1;
        border-top-left-radius: 200px;  
        border-top-right-radius: 200px;
        background:rgba(var(--vs-primary), 0.2);        
        border: 50px solid rgba(256,256,256,0.15);
        box-shadow: 0px 0px 0px 50px rgba(var(--vs-primary), 0.1);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    &:before{
        position: fixed;
        right: -130px;
        top: -130px;    
        content: "";
        width: 350px;
        height: 350px;
        z-index: -1;        
        border-bottom-left-radius: 200px;  
        border-bottom-right-radius: 200px;
         background:rgba(var(--vs-primary), 0.2);        
        border: 50px solid rgba(256,256,256,0.15);
        box-shadow: 0px 0px 0px 50px rgba(var(--vs-primary), 0.1);    
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    
    .login-inner {
        box-shadow: 0px 10px 24px 1px rgba(var(--vs-primary), 0.2);
        float: left;
        width: 100%;    
        border-radius: 2.00em 0px 0px 2.00em;
        background-color: #ffffff;            
        position: relative;
        margin: 0px;
        .login-colors-coustmize{
            position: absolute;
            right: -10px;
            top: -10px;
            z-index: 2;
            ul{
                float: left;                                                     
                width: 100%;
                background: #ffffff;
                text-align: center;
                display: flex;
                justify-content: center;                
                align-items: center;
                padding: 16px 4px;
                border-radius: 0.8em;
                box-shadow: 0px 14px 24px 1px rgba(var(--vs-primary), 0.2);           
                margin-top: 24px;
                position: relative;   
                margin-left: 12px;  
                display: none;
                &.activeToggle{
                    display: flex;
                }           
                &:after{                    
                    right: 0;
                    border-color: transparent transparent #fff #fff;
                    -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
                    -webkit-transform: rotate(136deg);
                    transform: rotate(136deg);                    
                    box-shadow: -2px 2px 9px -2px rgba(var(--vs-primary), 0.2);
                    margin-left: 50%;
                    content: "";
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top:1px;
                        
                        text-align: center;
                    border: 0.75em solid black;            
                }                
                li{                                 
                    float: left;
                    width: 36px;
                    height: 36px;
                    border: 4px solid #fff;
                    box-shadow: 0px 0px 0px 1px #ccc;
                    border-radius: 50%;                    
                    margin: 8px 4px;
                    cursor: pointer;
                }
            }
        }
    }
    .login-right{
        float: left;
        width: 100%;
        position: relative;
        height: 100%;                
    }
    .login-bg{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(var(--vs-primary), 0.3);
            float: left;
            clip-path: circle(88% at 98% 49%);            
            &:after{
                width: 80%;
                position: absolute;
                right: 0px;
                top: 0;
                content: "";
                background:rgba(var(--vs-primary), 0.3);
                float: left;
                clip-path: circle(79% at 100% 48%);
                height: 100%;
            }
            &:before{
                width: 60%;
                position: absolute;
                right: 0px;
                top: 0;
                content: "";
                background:rgba(var(--vs-primary), 0.3);
                float: left;
                clip-path:circle(63% at 98% 50%);
                height: 100%;
                z-index: 1;
            }
        }
        .login-img{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            z-index: 1;
            padding-left: 90px;
            img{
                max-width: 280px;
            }
        }
        .login-form{
            height: 100%;
            display: flex;
            align-content: center;
            padding: 148px 72px;
            flex-wrap:wrap;
            h2{
                float: left;
                width: 100%;
                font-weight: 700;
                font-size: 32px;
                margin-bottom: 48px;    
                -webkit-background-clip: text !important;
                background: -webkit-linear-gradient(45deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.5) 40%);            
                -webkit-text-fill-color: transparent !important;
                    
            }
            form{
                float: left;
                width: 100%;
                .form-grp{
                    float: left;
                    width: 100%;
                    margin-bottom: 40px;
                    position: relative;                    
                    svg{
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        left: 20px;
                        top: 8px;
                        z-index: 1;
                        path{
                            fill:rgba(var(--vs-primary), 1);
                        }
                        
                    }
                    .input-span-placeholder{
                        padding-left: 4.2rem !important;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    .vs-inputx{
                        border: 0px !important;
                        box-shadow: rgba(var(--vs-primary), 0.4) -4px 4px 9px -2px;
                        background: #fff;
                        padding: 0.85em 1.5rem 0.85em 4.2rem !important;
                        border-radius: 2.00em;
                        &:focus{
                             box-shadow: rgba(var(--vs-primary), 0.6) -4px 4px 9px 0.5px;
                        }
                    }
                }
                .forgot-pass{
                    font-weight: 600;
                }
            }            
        }               
}

.vs-button.vs-radius.login-theme-btn{
    border-radius: 50% !important;
    width: 50px!important;
    height: 50px!important;
    float: right !important;
    box-shadow: 0px 0px 10px 2px rgba(var(--vs-primary), 0.3);
    i{
        font-size: 20px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {  
  -webkit-text-fill-color: #666;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Resposive */
@media (max-width: 1365px) {
    .login-main .login-inner .login-colors-coustmize ul{
        margin-left: 0px;
    }
}
@media (max-width: 1199px) {
    .login-main .login-bg{
        -webkit-clip-path: circle(85% at 98% 49%);
        clip-path: circle(85% at 100% 49%);
    }
    .login-main .login-bg:before{
        -webkit-clip-path: circle(50% at 100% 50%);
        clip-path: circle(50% at 100% 50%);
    }
    .login-main .login-bg:after{
        -webkit-clip-path: circle(70% at 100% 48%);
        clip-path: circle(70% at 100% 48%);
    }
    .login-main:after{
        width: 250px;
        height: 250px;
    }
    .login-main:before{
       width: 250px;
       height: 250px;  
    }
}
@media (max-width: 991px) {
    .login-main .login-form {
        padding: 68px 32px;
    }
    .login-main .login-img img {
        max-width: 200px;
    }    
}

@media (max-width: 767px) {
    .login-main .login-form{
        padding: 32px 16px;
    }
    .login-main .login-img{
        padding-left: 0px;
    }
    .login-main .login-bg{
        display: none;
    }
    .login-main .login-img{
        background:  rgba(var(--vs-primary), 0.6);
        border-radius: 2.00em 2.00em 0px 0px ;
        padding: 40px 0px;
    }
    .login-main .login-inner{
        border-radius: 2.00em;
    }
    .login-inner .vx-col:nth-child(2){
        order: 2;
    }    
}

@media (max-width: 575px) {
    .login-main .login-form h2{
        margin-bottom: 24px;
        font-size: 24px;
    }
    .login-main .login-form form .form-grp{
        margin-bottom: 24px;
    }
    .login-main .login-form {
        padding: 24px 0px;
    }
    .login-main .login-img{
        padding:24px 24px;
    }
    .login-main .login-img img {
        max-width: 65%;
    }
    .login-main:after{
        width: 160px;
        height: 160px;
    }
    .login-main:before{
       width: 160px;
       height: 160px;  
    }
}

</style>
